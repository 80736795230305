<script setup lang="ts">
const customizer = useCustomizerStore()

defineComponent({
  name: 'BlankLayout'
})
</script>

<template>
  <v-app :theme="customizer.actTheme">
    <NuxtPage />
  </v-app>
</template>
